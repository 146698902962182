var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"finance-tracker-nested-medium-type-table"},[_c('el-table',{staticClass:"el-table-slim w-full",attrs:{"data":_vm.records,"header-row-class-name":"hidden"}},[_c('el-table-column',{attrs:{"width":"auto"}}),_c('el-table-column',{attrs:{"label":"Medium Type","align":"top","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Gross Revenue","align":"right","width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.grossRevenuePeriodDue))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Supplier Earnings","align":"right","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.supplierEarningsPeriodDue))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Net Revenue ($)","align":"right","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.netRevenueDollars))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Net Revenue (%)","align":"right","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("percent")(row.netRevenuePercent,1))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }