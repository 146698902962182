<template>
  <div>
    <export-button
      blue
      :is-exporting="isExporting"
      content="Export By Document"
      @click="showExportDialog = true"
    />

    <confirm-dialog
      v-if="showExportDialog"
      title="Export Accounting Documents"
      confirm-text="Export"
      width="30em"
      :is-saving="isExporting"
      @close="showExportDialog = false"
      @confirm="onExportAccountingData"
    >
      <h4 class="mb-2">Issued Date</h4>
      <el-date-picker
        v-model="exportAccountingInput.dateRange"
        type="daterange"
        range-separator="To"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        format="MM/dd/yyyy"
        :default-value="month"
      />
    </confirm-dialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import ExportButton from '@/components/Core/Table/ExportButton';

import { dateDb } from '@/utils/filters';
import { exportCsv } from '@/utils/helpers';
import { download as exportIcon } from '@/vendor/icons';
import moment from 'moment';

export default {
  components: {
    ExportButton,
    ConfirmDialog
  },
  props: {
    month: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExporting: false,
      showExportDialog: false,
      exportAccountingInput: {
        dateRange: [
          moment(this.month)
            .startOf('month')
            .toISOString(),
          moment(this.month)
            .endOf('month')
            .toISOString()
        ]
      },

      // Icons
      exportIcon
    };
  },
  computed: {
    exportAccountingDataUrl() {
      let input = this.exportAccountingInput;
      return (
        window._env.API_URL +
        '/export-accounting-by-document?' +
        new URLSearchParams({
          filter: JSON.stringify({
            'orderLineItem.documentLineItems.document.transaction_date': {
              '>=': dateDb(input.dateRange[0]),
              '<=': dateDb(input.dateRange[1])
            }
          })
        }).toString()
      );
    }
  },
  watch: {
    month: function(newVal) {
      newVal = moment(newVal);
      this.exportAccountingInput.dateRange = [
        newVal.startOf('month').toISOString(),
        newVal.endOf('month').toISOString()
      ];
    }
  },
  methods: {
    async onExportAccountingData() {
      this.isExporting = true;
      await exportCsv('AccountingDocs.csv', this.exportAccountingDataUrl);
      this.isExporting = false;
      this.showExportDialog = false;
    }
  }
};
</script>
