<script>
export default {
  props: {
    isLoading: Boolean,
    records: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      selection: []
    };
  },
  computed: {
    sortableRecords() {
      return this.records.map(r => ({
        ...r,
        grossRevenuePeriodDue: +r.grossRevenuePeriodDue,
        supplierEarningsPeriodDue: +r.supplierEarningsPeriodDue,
        netRevenueDollars: +r.netRevenueDollars
      }));
    },
    tableMaxHeight() {
      return this.windowHeight * 0.6;
    }
  },
  watch: {
    records() {
      this.$nextTick(() => {
        for (let row of this.sortableRecords) {
          this.$refs.table.toggleRowSelection(row, true);
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    onSelect(selection) {
      this.selection = selection;
      this.$emit('selection', selection);
    }
  }
};
</script>
