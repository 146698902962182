<template>
  <section id="page-finance-tracker" class="page-content">
    <finance-tracker-ads-query
      fetch-policy="network-only"
      :pager="pager"
      :loading.sync="isLoadingAds"
      @data="onReceivedAds"
    />

    <finance-tracker-ads-summary-query
      fetch-policy="network-only"
      :pager="pager"
      @data="summary = $event.nationalFinanceTracker.nationalAdsSummary"
    />

    <finance-tracker-search-tools-query
      @data="searchTools = $event.nationalFinanceTracker.searchTools"
    />

    <the-page-header class="py-4">
      <template #title>
        Finance Tracker
      </template>
      <template #subtitle>
        All national billing and invoicing running through the platform
      </template>
    </the-page-header>

    <div class="flex flex-row border-top">
      <div class="flex-shrink-0">
        <collapsible-search-group-sidebar
          v-model="searchInput"
          :search-tools="searchTools"
          :search-groups-info="FinanceTrackerSearchGroups"
          :is-loading="!searchTools"
          :filter="pager.filter"
          show-labels
          @search="pager = { ...pager, ...$event }"
        >
          <template #before>
            <div class="mb-2">
              <h4 class="mb-2 ">Ad Refs</h4>
              <el-input
                :model-value="pager.filter.ad_refs"
                type="textarea"
                :rows="5"
                @input="onUpdateAdRefs"
              />
            </div>
          </template>
        </collapsible-search-group-sidebar>
      </div>
      <div class="flex flex-col flex-1 pl-8 py-8 min-w-40 border-left">
        <table-toolbar
          class="pt-4"
          placeholder="Search Ads, Invoices, Bills, etc."
          :value="pager.filter"
          hide-export
          @input="pager = { ...pager, filter: $event }"
        >
          <template #before>
            <month-filter
              :value="pager.filter.adDeliveryMonth"
              @input="onMonthChange"
            >
              <template #label>Select a billing period</template>
              <template #hintText>
                All $ amounts will be constrained to this period
              </template>
            </month-filter>
          </template>
          <finance-tracker-ads-summary class="mx-6" :summary="summary" />
          <preview-payments-button class="mr-3" />
          <preview-invoices-button
            v-if="$feature('ft-invoices')"
            class="mr-3"
          />
          <export-accounting-data-button
            class="mr-3"
            :month="pager.filter.adDeliveryMonth"
          />
          <export-documents-button
            class="mr-3"
            :month="pager.filter.adDeliveryMonth"
          />
          <refresh-button
            class="p-2"
            :is-refreshing="isLoadingAds"
            :pager.sync="pager"
          />
        </table-toolbar>

        <div class="mt-5">
          <finance-tracker-table
            :ads="ads"
            :pager.sync="pager"
            :is-loading="isLoadingAds"
            @refresh="pager = { ...pager }"
          />
          <div class="flex justify-center items-center mt-5">
            <refresh-button
              :is-refreshing="isLoadingAds"
              :pager.sync="pager"
              class="mr-3"
            />
            <pagination
              v-if="paginatorInfo"
              :info="paginatorInfo"
              :pager.sync="pager"
            />
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <job-batch v-if="jobBatch" :batch.sync="jobBatch" />
    </transition>
  </section>
</template>

<script>
import PreviewInvoicesButton from '@/components/Admin/Accounting/FinanceTracker/Buttons/PreviewInvoicesButton';
import PreviewPaymentsButton from '@/components/Admin/Accounting/FinanceTracker/Buttons/PreviewPaymentsButton';
import RefreshButton from '@/components/Admin/Accounting/FinanceTracker/RefreshButton';
import FinanceTrackerAdsSummary from '@/components/Admin/Accounting/FinanceTracker/Summary/FinanceTrackerAdsSummary';
import FinanceTrackerTable from '@/components/Admin/Accounting/FinanceTracker/Tables/FinanceTrackerTable';
import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import JobBatch from '@/components/Core/JobBatch';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import MonthFilter from '@/components/Core/MonthFilter';
import TableToolbar from '@/components/Core/Table/FilterToolbar';
import Pagination from '@/components/Core/Table/Pagination';
import FinanceTrackerAdsQuery from '@/components/Queries/Accounting/FinanceTrackerAdsQuery';
import FinanceTrackerAdsSummaryQuery from '@/components/Queries/Accounting/FinanceTrackerAdsSummaryQuery';
import FinanceTrackerSearchToolsQuery from '@/components/Queries/Accounting/FinanceTrackerSearchToolsQuery';
import { FinanceTrackerSearchGroups } from '@/constants';
import { debounce } from 'lodash';
import ExportAccountingDataButton from './Buttons/ExportAccountingDataButton';
import ExportDocumentsButton from './Buttons/ExportDocumentsButton';

export default {
  components: {
    ExportDocumentsButton,
    FinanceTrackerAdsSummaryQuery,
    RefreshButton,
    JobBatch,
    FinanceTrackerAdsSummary,
    PreviewInvoicesButton,
    ExportAccountingDataButton,
    PreviewPaymentsButton,
    FinanceTrackerSearchToolsQuery,
    FinanceTrackerTable,
    FinanceTrackerAdsQuery,
    Pagination,
    TableToolbar,
    CollapsibleSearchGroupSidebar,
    ThePageHeader,
    MonthFilter
  },
  data() {
    return {
      isLoadingAds: false,
      ads: [],
      summary: {},
      paginatorInfo: null,
      searchInput: {},
      searchTools: null,
      FinanceTrackerSearchGroups
    };
  },
  computed: {
    pager: {
      get() {
        return this.$store.getters['financeTrackerAds/pager'];
      },
      set(value) {
        this.$store.commit('financeTrackerAds/setPager', value);
      }
    },
    jobBatch: {
      get() {
        return this.$store.getters['financeTrackerAds/getJobBatch'];
      },
      set(value) {
        this.$store.commit('financeTrackerAds/setJobBatch', value);
      }
    }
  },
  methods: {
    onReceivedAds({ nationalFinanceTracker: { nationalAds } }) {
      this.ads = nationalAds.data;
      this.paginatorInfo = nationalAds.paginatorInfo;
    },
    onUpdateAdRefs: debounce(function(adRefs) {
      if (adRefs) {
        adRefs = adRefs
          .trim()
          .replace(/,$/, '')
          .replace(/[^Aa\-,\d\n]/g, '')
          .replace(/,/g, '\n')
          .split('\n')
          .map(ref => ref.trim());
      }

      this.pager = {
        ...this.pager,
        filter: {
          ...this.pager.filter,
          ref: adRefs || undefined,
          ad_refs: undefined
        }
      };
    }, 500),
    onMonthChange(adDeliveryMonth) {
      this.pager = {
        ...this.pager,
        filter: {
          ...this.pager.filter,
          adDeliveryMonth
        }
      };
    }
  }
};
</script>
