var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accounting-document-table",on:{"click":function($event){$event.stopPropagation();}}},[_c('el-table',{staticClass:"el-table-slim",attrs:{"data":_vm.documents,"border":"","default-sort":{ prop: 'transaction_date', order: 'descending' }}},[(_vm.$can('manage_accounting_documents'))?[_c('el-table-column',{attrs:{"label":"ID","sortable":"","prop":"provider_id","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_tracked_by_source)?[_vm._v(" "+_vm._s(row.provider_id || 'Not Synced')+" ")]:[_vm._v("N/A")]]}}],null,false,327480410)}),_c('el-table-column',{attrs:{"label":"Ref","sortable":"","prop":"ref","width":"80"}}),_c('el-table-column',{attrs:{"label":"User","sortable":"","prop":"user.email","width":"160"}})]:_vm._e(),_c('el-table-column',{attrs:{"label":"Doc Number","sortable":"","prop":"doc_number","width":"170"}}),_c('el-table-column',{attrs:{"label":"Date","sortable":"","prop":"transaction_date","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("date")(row.transaction_date)))]}}])}),_c('el-table-column',{attrs:{"label":"Period","sortable":"","prop":"start_date","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("date")(row.start_date))+" to "+_vm._s(_vm._f("date")(row.end_date))+" ")]}}])}),(_vm.showLineItemAmount)?_c('el-table-column',{attrs:{"label":"Amount","sortable":"","prop":"lineItemAmount","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.lineItemAmount))+" ")]}}],null,false,4266403158)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Bill Total","sortable":"","prop":"amount","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("currency")(row.amount)))]}}])}),_c('el-table-column',{attrs:{"label":"Status","sortable":"","prop":"status","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm.getStatusTagColor(row)}},[_vm._v(" "+_vm._s(_vm.getStatus(row))+" ")])]}}])}),(_vm.$can('manage_accounting_documents'))?[_c('el-table-column',{attrs:{"label":"Url","sortable":"","prop":"provider_url","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.delete_started_at)?[(row.is_tracked_by_source)?[(row.provider_id)?_c('a',{attrs:{"href":row.provider_url,"target":"_blank"}},[_vm._v(" "+_vm._s(row.provider_url)+" ")]):_c('a',{on:{"click":function($event){return _vm.$emit('sync', row.id)}}},[(_vm.isSyncing)?[_vm._v("Syncing...")]:[_vm._v("Sync To Provider")]],2)]:[_vm._v(" (Not tracked in QuickBooks) ")]]:_vm._e()]}}],null,false,1235569084)}),(_vm.allowDelete)?_c('el-table-column',{attrs:{"width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!_vm.isDeleting(row))?_c('a',{staticClass:"text-lg",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete', row.id)}}},[_c('icon',{attrs:{"icon":_vm.deleteIcon}})],1):_vm._e()]}}],null,false,2186932308)}):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }