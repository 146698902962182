<template>
  <div class="finance-tracker-ad-column" @click.stop>
    <div>
      <a :href="adUrl" target="_blank">{{ ad.ref }}</a>
    </div>

    <div class="flex text-xs text-dark-silver leading-normal">
      <div>
        {{ ad.order.ref + ' | ' }}
      </div>
      <div>
        {{ ad.campaign.ref }}
      </div>
    </div>
    <div class="text-pewter text-sm">SKU: {{ ad.sku }}</div>
    <div v-if="ad.adGroup" class="mt-3">
      <div class="text-dark-gray font-semibold">Ad Group</div>
      <a :href="adGroupUrl" target="_blank">
        {{ ad.adGroup.name }} ({{ ad.adGroup.id }})
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ad: {
      type: Object,
      default: null
    }
  },
  computed: {
    adUrl() {
      return `${window._env.API_URL}/nova/resources/ads/${this.ad.id}`;
    },
    adGroupUrl() {
      return `${window._env.API_URL}/nova/resources/ad-groups/${this.ad.adGroup.id}`;
    }
  }
};
</script>
