<template>
  <div class="flex flex-grow">
    <finance-tracker-summary-item label="in Total" bold bar>
      {{ summary.total }} Ads
    </finance-tracker-summary-item>

    <finance-tracker-summary-item label="Gross Revenue" bar>
      <div class="font-semibold">
        {{ summary.grossRevenuePeriodDue | currency }}
      </div>
      <div class="text-sm text-dark-silver">
        {{ summary.periodGrossRevenue | currency }}
      </div>
    </finance-tracker-summary-item>

    <finance-tracker-summary-item label="Supplier Earnings">
      <div class="font-semibold">
        {{ summary.supplierEarningsPeriodDue | currency }}
      </div>
      <div class="text-sm text-dark-silver">
        {{ summary.periodSupplierEarnings | currency }}
      </div>
    </finance-tracker-summary-item>
  </div>
</template>
<script>
import FinanceTrackerSummaryItem from '@/components/Admin/Accounting/FinanceTracker/Summary/FinanceTrackerSummaryItem';
import { sumBy } from 'lodash';

export default {
  name: 'FinanceTrackerAdsSummary',
  components: { FinanceTrackerSummaryItem },
  props: {
    summary: {
      type: Object,
      required: true
    }
  }
};
</script>
