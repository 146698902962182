<script>
import { getFinanceTrackerSearchTools } from '@/graphql/admin/accounting/financeTrackerQueries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  query() {
    return {
      query: getFinanceTrackerSearchTools
    };
  }
};
</script>
