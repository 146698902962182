<template>
  <confirm-dialog
    class="dialog-header-alt"
    :hide-buttons="true"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="text-2xl font-bold">
        Preview {{ category }}: {{ dateRange }}
      </h2>
    </div>

    <div class="flex flex-col xl:flex-row">
      <div class="flex-grow flex">
        <finance-tracker-records-summary
          :records="records ? records : []"
          :category="category"
        />
      </div>

      <transition name="fade">
        <div
          v-if="records && records.length"
          class="flex space-x-2 items-center mt-3 xl:mt-0"
        >
          <div>
            <send-invoices-button
              v-if="isInvoices"
              :filter="filter"
              :records="records"
            />
            <send-payments-button
              v-if="isPayments"
              :filter="filter"
              :records="records"
            />
          </div>
          <export-button
            blue
            :is-exporting="isExporting"
            content="Export"
            @click="$emit('export')"
          />
        </div>
      </transition>
    </div>

    <div class="preview-table mt-5">
      <slot />
    </div>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import moment from 'moment';
import { date as dateFormat } from '@/utils/filters';
import ExportButton from '@/components/Core/Table/ExportButton';
import FinanceTrackerRecordsSummary from '@/components/Admin/Accounting/FinanceTracker/Summary/FinanceTrackerRecordsSummary';
import SendInvoicesButton from '@/components/Admin/Accounting/FinanceTracker/Buttons/SendInvoicesButton';
import SendPaymentsButton from '@/components/Admin/Accounting/FinanceTracker/Buttons/SendPaymentsButton';

const CATEGORY = { PAYMENTS: 'Payments', INVOICES: 'Invoices' };

export default {
  components: {
    SendInvoicesButton,
    FinanceTrackerRecordsSummary,
    ExportButton,
    ConfirmDialog,
    SendPaymentsButton
  },
  props: {
    category: {
      type: String,
      required: true,
      validator: value => Object.values(CATEGORY).indexOf(value) !== -1
    },
    filter: {
      type: Object,
      required: true
    },
    records: {
      type: Array,
      default: null
    },
    isExporting: Boolean
  },
  computed: {
    isInvoices() {
      return this.category === CATEGORY.INVOICES;
    },
    isPayments() {
      return this.category === CATEGORY.PAYMENTS;
    },
    dateRange() {
      const startDate = moment(this.filter.adDeliveryMonth).startOf('month');
      const endDate = startDate.clone().endOf('month');
      return dateFormat(startDate) + ' - ' + dateFormat(endDate);
    }
  }
};
</script>
