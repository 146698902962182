<template>
  <div class="w-72 relative">
    <h4 v-if="$slots.label" class="font-size-sm input-label-offset">
      <slot name="label"></slot>
    </h4>
    <el-date-picker
      :value="month"
      :clearable="false"
      type="month"
      placeholder="Select Month"
      format="MMMM yyyy"
      @input="onMonthChange"
    ></el-date-picker>
    <div v-if="$slots.hintText" class="text-gray text-md mt-2 ml-1">
      <slot name="hintText"></slot>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { dateDb } from '@/utils/filters';

export default {
  props: {
    value: {
      type: [Object, String],
      default: null
    }
  },
  computed: {
    month() {
      return this.value ? moment(this.value) : moment().subtract(1, 'month');
    }
  },
  methods: {
    onMonthChange(month) {
      this.$emit('input', dateDb(month));
    }
  }
};
</script>
