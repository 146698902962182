var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-grow space-x-8"},[_c('finance-tracker-summary-item',{attrs:{"label":"Will be sent","bold":"","bar":"","loose":""}},[_c('tween',{attrs:{"value":_vm.count},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("integer")(value))+" "+_vm._s(_vm.category)+" ")]}}])})],1),_c('finance-tracker-summary-item',{attrs:{"label":"Gross Revenue","bar":"","loose":""}},[_c('tween',{attrs:{"value":_vm.grossRevenueTotal},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}}])})],1),_c('finance-tracker-summary-item',{attrs:{"label":"Supplier Earnings","bar":"","loose":""}},[_c('tween',{attrs:{"value":_vm.supplierEarningsTotal},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}}])})],1),_c('finance-tracker-summary-item',{attrs:{"label":"Net Revenue","loose":""}},[_c('tween',{attrs:{"value":_vm.netRevenueTotal},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }