var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"finance-tracker-chargeable-buyers-table"},[_c('el-table',{ref:"table",staticClass:"el-table-slim w-full el-table-nested",attrs:{"data":_vm.isLoading ? [] : _vm.sortableRecords,"border":"","default-expand-all":"","max-height":_vm.tableMaxHeight},on:{"selection-change":_vm.onSelect},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.records,"empty-message":"No ads available to invoice. Try adjusting your filters.","error-message":"There was a problem loading the buyers. Please try again later."}})]},proxy:true}])},[_c('el-table-column',{attrs:{"fixed":"left","type":"selection","width":"40"}}),_c('el-table-column',{attrs:{"fixed":"left","type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('finance-tracker-nested-medium-type-table',{attrs:{"records":row.mediumTypes}})]}}])}),_c('el-table-column',{attrs:{"label":"Buyer","align":"top","sortable":"","prop":"buyerName","fixed":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.buyerName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Campaign","align":"top","sortable":"","prop":"campaignName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.campaignName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Medium Type","align":"top","width":"150"}}),_c('el-table-column',{attrs:{"label":"Gross Revenue","align":"right","sortable":"","prop":"grossRevenuePeriodDue","width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.grossRevenuePeriodDue))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Supplier Earnings","align":"right","sortable":"","prop":"supplierEarningsPeriodDue","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.supplierEarningsPeriodDue))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Net Revenue ($)","align":"right","sortable":"","prop":"netRevenueDollars","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.netRevenueDollars))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Net Revenue (%)","align":"right","sortable":"","prop":"netRevenuePercent","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("percent")(row.netRevenuePercent,1))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }