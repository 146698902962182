<template>
  <div class="job-batch">
    <h3>{{ batch.name }}</h3>
    <div class="mt-3">
      <el-progress :percentage="100 * liveBatch.progress" />
    </div>
    <div class="flex space-x-10 mt-3">
      <summary-item :amount="liveBatch.total_jobs" label="Total" />
      <summary-item
        :amount="liveBatch.processed_jobs"
        label="Succeeded"
        color="green"
      />
      <summary-item
        :amount="liveBatch.failed_jobs"
        label="Failed"
        color="red"
      />
    </div>
    <a class="close-button" @click="$emit('update:batch', null)">
      <icon :icon="closeIcon" />
    </a>
  </div>
</template>

<script>
import { cancel as closeIcon } from '@/vendor/icons';
import SummaryItem from '@/components/Supplier/Billing/SummaryItem';

export default {
  components: { SummaryItem },
  props: {
    batch: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      liveBatch: this.batch,

      // Icons
      closeIcon
    };
  },
  computed: {
    liveBatchUrl() {
      return window._env.API_URL + '/job-batch/' + this.batch.id;
    },
    pendingJobs() {
      return this.liveBatch.pending_jobs - this.liveBatch.failed_jobs;
    }
  },
  watch: {
    batch() {
      if (this.batch.id !== this.liveBatch.id) {
        this.liveBatch = this.batch;
        this.poll();
      }
    }
  },
  mounted() {
    this.poll();
  },
  methods: {
    async poll() {
      this.liveBatch = await fetch(this.liveBatchUrl).then(r => r.json());
      this.$emit('update:batch', this.liveBatch);

      if (this.pendingJobs > 0) {
        setTimeout(() => this.poll(), 3000);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.job-batch {
  position: fixed;
  right: 2em;
  bottom: 2em;
  padding: 2em 4em 2em 2em;
  border-radius: 1em;
  background: white;
  box-shadow: 6px 6px 20px -3px;
  width: 30em;
  z-index: 1000;

  .close-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    padding: 0.5em;
  }
}
</style>
