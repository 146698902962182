<template>
  <div class="preview-invoices-button">
    <el-button
      class="button button-green whitespace-no-wrap"
      @click="showDialog = true"
    >
      Invoices <icon :icon="previewIcon" class="ml-1" />
    </el-button>
    <template v-if="showDialog">
      <finance-tracker-chargeable-buyers-query
        :filter="pager.filter"
        :loading.sync="isLoading"
        @data="onData($event)"
      />
      <finance-tracker-preview-dialog
        v-if="showDialog"
        :filter="pager.filter"
        category="Invoices"
        :records="chargeableBuyersSelection"
        :is-exporting="isExporting"
        @close="showDialog = false"
        @export="onExport"
      >
        <finance-tracker-chargeable-buyers-table
          :is-loading="isLoading"
          :records="chargeableBuyers"
          @selection="chargeableBuyersSelection = $event"
        />
      </finance-tracker-preview-dialog>
    </template>
  </div>
</template>

<script>
import FinanceTrackerPreviewDialog from '@/components/Admin/Accounting/FinanceTracker/FinanceTrackerPreviewDialog';
import FinanceTrackerChargeableBuyersQuery from '@/components/Queries/Accounting/FinanceTrackerChargeableBuyersQuery';
import FinanceTrackerChargeableBuyersTable from '@/components/Admin/Accounting/FinanceTracker/Tables/FinanceTrackerChargeableBuyersTable';
import FinanceTrackerPreviewButtonMixin from '@/components/Admin/Accounting/FinanceTracker/Buttons/FinanceTrackerPreviewButtonMixin';
import { exportCsv } from '@/utils/helpers';
import { eye as previewIcon } from '@/vendor/icons';

export default {
  components: {
    FinanceTrackerChargeableBuyersTable,
    FinanceTrackerChargeableBuyersQuery,
    FinanceTrackerPreviewDialog
  },
  mixins: [FinanceTrackerPreviewButtonMixin],
  data() {
    return {
      isLoading: false,
      isExporting: false,
      showDialog: false,
      chargeableBuyers: null,
      chargeableBuyersSelection: null,

      // Icons
      previewIcon
    };
  },
  computed: {
    pager: {
      get() {
        return this.$store.getters['financeTrackerAds/pager'];
      },
      set(value) {
        this.$store.commit('financeTrackerAds/setPager', value);
      }
    },
    exportUrl() {
      return (
        window._env.API_URL +
        '/accounting/export/chargeableBuyers?' +
        new URLSearchParams({
          filter: JSON.stringify(this.pager.filter)
        }).toString()
      );
    }
  },
  methods: {
    onData(ev) {
      this.chargeableBuyers = this.prepareRecords(
        ev.nationalFinanceTracker.chargeableBuyers
      );
    },
    async onExport() {
      this.isExporting = true;
      await exportCsv('ChargeableBuyers.csv', this.exportUrl);
      this.isExporting = false;
    }
  }
};
</script>
