<script>
import { QueryMixin } from '@/components/Core/Helpers';
import { getFinanceTrackerChargeableBuyers } from '@/graphql/admin/accounting/financeTrackerQueries.graphql';

export default {
  mixins: [QueryMixin],
  props: {
    options: {
      type: Object,
      default: () => ({
        fetchPolicy: 'no-cache'
      })
    },
    filter: {
      type: Object,
      required: true
    }
  },
  query({ filter }) {
    return {
      query: getFinanceTrackerChargeableBuyers,
      variables: {
        filter
      }
    };
  }
};
</script>
