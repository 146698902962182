<template>
  <div class="finance-tracker-nested-medium-type-table">
    <el-table
      :data="records"
      header-row-class-name="hidden"
      class="el-table-slim w-full"
    >
      <el-table-column width="auto" />

      <el-table-column label="Medium Type" align="top" width="150">
        <template v-slot="{ row }">
          {{ row.name }}
        </template>
      </el-table-column>

      <el-table-column label="Gross Revenue" align="right" width="155">
        <template v-slot="{ row }">
          {{ row.grossRevenuePeriodDue | currency }}
        </template>
      </el-table-column>

      <el-table-column label="Supplier Earnings" align="right" width="170">
        <template v-slot="{ row }">
          {{ row.supplierEarningsPeriodDue | currency }}
        </template>
      </el-table-column>

      <el-table-column label="Net Revenue ($)" align="right" width="170">
        <template v-slot="{ row }">
          {{ row.netRevenueDollars | currency }}
        </template>
      </el-table-column>

      <el-table-column label="Net Revenue (%)" align="right" width="170">
        <template v-slot="{ row }">
          {{ row.netRevenuePercent | percent(1) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      type: Array,
      default: null
    }
  }
};
</script>
