<template>
  <div>
    <div class="whitespace-no-wrap">
      Period Due: <b>{{ ad.grossRevenuePeriodDue | currency }}</b>
    </div>

    <div>
      <div class="flex mt-1 whitespace-no-wrap">
        <div>Period Invoiced:</div>
        <finance-tracker-invoices-popover
          class="ml-1"
          :invoices="periodInvoices"
          @change="$emit('refresh')"
        >
          <template>
            <b>
              {{ periodInvoicedAmount | currency }}
            </b>
          </template>
        </finance-tracker-invoices-popover>
        <div v-if="isPeriodSynced">
          <icon :icon="syncedIcon" class="ml-1 text-green" />
        </div>
      </div>
    </div>

    <div>
      <div class="flex mt-1 whitespace-no-wrap">
        <div>Lifetime Invoiced:</div>
        <finance-tracker-invoices-popover
          class="ml-1"
          :invoices="invoices"
          @change="$emit('refresh')"
        >
          <template>
            <b>
              {{ ad.orderLineItem.invoiced_amount | currency }}
            </b>
          </template>
        </finance-tracker-invoices-popover>
        <div v-if="isLifetimeSynced">
          <icon :icon="syncedIcon" class="ml-1 text-green" />
        </div>
      </div>
      <div class="text-dark-silver">
        of {{ ad.orderLineItem.charge_buyer | currency }} ({{
          percentInvoiced | percent
        }})
      </div>
    </div>

    <div class="whitespace-no-wrap mt-1">
      <template v-if="isFlatRate">{{ chargeType }} Invoicing</template>
      <template v-else>
        {{ chargeRate | currency }} / {{ chargeType }} ({{ chargeMetric }})
      </template>
    </div>

    <document-deleting-warning
      class="mt-1"
      doc-type="invoice"
      :documents="invoices"
    />
  </div>
</template>

<script>
import {
  AccountingDocumentType,
  AdGroupMetric,
  AdGroupRateType
} from '@/constants';
import { ok as syncedIcon } from '@/vendor/icons';
import DocumentDeletingWarning from '@/components/Admin/Accounting/FinanceTracker/Columns/DocumentDeletingWarning';
import FinanceTrackerInvoicesPopover from '@/components/Admin/Accounting/FinanceTracker/Columns/FinanceTrackerInvoicesPopover';
import { dateDb } from '@/utils/filters';

export default {
  components: {
    FinanceTrackerInvoicesPopover,
    DocumentDeletingWarning
  },
  props: {
    ad: {
      type: Object,
      default: null
    },
    billingPeriod: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      AccountingDocumentType,
      syncedIcon
    };
  },
  computed: {
    isFlatRate() {
      return this.chargeType === AdGroupRateType.FLAT_RATE.name;
    },
    chargeType() {
      return AdGroupRateType[
        this.ad.charge_type ||
          this.ad.adGroup?.charge_type ||
          AdGroupRateType.FLAT_RATE.value
      ].name;
    },
    chargeMetric() {
      return AdGroupMetric[
        this.ad.charge_metric || this.ad.adGroup?.charge_metric
      ].name;
    },
    chargeRate() {
      return this.ad.charge_rate || this.ad.adGroup?.charge_rate;
    },
    percentInvoiced() {
      return (
        this.ad.orderLineItem.invoiced_amount /
        this.ad.orderLineItem.charge_buyer
      );
    },
    invoices() {
      return (this.ad.orderLineItem.invoiceLineItems || [])
        .filter(ili => ili.invoice.is_tracked_by_source)
        .map(ili => ({
          ...ili.invoice,
          lineItemAmount: ili.amount
        }));
    },
    billingPeriodDate() {
      return dateDb(this.billingPeriod);
    },
    periodInvoices() {
      return this.invoices.filter(i => {
        return (
          dateDb(i.end_date) >= this.billingPeriodDate &&
          dateDb(i.start_date) <= this.billingPeriodDate
        );
      });
    },
    periodInvoicedAmount() {
      return this.periodInvoices.reduce((sum, i) => sum + i.lineItemAmount, 0);
    },
    isPeriodSynced() {
      return (
        this.periodInvoices.length > 0 &&
        this.periodInvoices.every(i => i.provider_id)
      );
    },
    isLifetimeSynced() {
      return (
        this.invoices.length > 0 && this.invoices.every(i => i.provider_id)
      );
    }
  }
};
</script>
