<template>
  <div>
    <div class="whitespace-no-wrap">
      Period Due: <b>{{ ad.supplierEarningsPeriodDue | currency }}</b>
    </div>

    <div>
      <div class="flex mt-1 whitespace-no-wrap">
        <div>Period Billed:</div>
        <finance-tracker-bills-popover
          class="ml-1"
          :bills="periodBills"
          @change="$emit('refresh')"
        >
          <template>
            <b>
              {{ periodBilledAmount | currency }}
            </b>
          </template>
        </finance-tracker-bills-popover>
        <div v-if="isPeriodSynced">
          <icon :icon="syncedIcon" class="ml-1 text-green" />
        </div>
      </div>
    </div>

    <div>
      <div class="flex mt-1 whitespace-no-wrap">
        <div>Lifetime Billed:</div>
        <finance-tracker-bills-popover
          class="ml-1"
          :bills="bills"
          @change="$emit('refresh')"
        >
          <template>
            <b>
              {{ ad.orderLineItem.billed_amount | currency }}
            </b>
          </template>
        </finance-tracker-bills-popover>
        <div v-if="isLifetimeSynced">
          <icon :icon="syncedIcon" class="ml-1 text-green" />
        </div>
      </div>
      <div class="text-dark-silver">
        of {{ ad.orderLineItem.pay_supplier | currency }} ({{
          percentBilled | percent
        }})
      </div>
    </div>

    <div class="whitespace-no-wrap mt-1">
      <template v-if="isFlatRate">{{ payoutType }} Billing</template>
      <template v-else>
        {{ payoutRate | currency }} / {{ payoutType }} ({{ payoutMetric }})
      </template>
    </div>

    <document-deleting-warning
      class="mt-1"
      doc-type="bill"
      :documents="bills"
    />
  </div>
</template>

<script>
import { AdGroupMetric, AdGroupRateType } from '@/constants';
import { ok as syncedIcon } from '@/vendor/icons';
import DocumentDeletingWarning from '@/components/Admin/Accounting/FinanceTracker/Columns/DocumentDeletingWarning';
import FinanceTrackerBillsPopover from '@/components/Admin/Accounting/FinanceTracker/Columns/FinanceTrackerBillsPopover';
import { dateDb } from '@/utils/filters';

export default {
  components: {
    FinanceTrackerBillsPopover,
    DocumentDeletingWarning
  },
  props: {
    ad: {
      type: Object,
      default: null
    },
    billingPeriod: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      syncedIcon
    };
  },
  computed: {
    isFlatRate() {
      return this.payoutType === AdGroupRateType.FLAT_RATE.name;
    },
    payoutType() {
      return AdGroupRateType[
        this.ad.payout_type ||
          this.ad.adGroup?.payout_type ||
          AdGroupRateType.FLAT_RATE.value
      ].name;
    },
    payoutMetric() {
      return AdGroupMetric[
        this.ad.payout_metric || this.ad.adGroup?.payout_metric
      ].name;
    },
    payoutRate() {
      return this.ad.payout_rate || this.ad.adGroup?.payout_rate;
    },
    percentBilled() {
      return (
        this.ad.orderLineItem.billed_amount / this.ad.orderLineItem.pay_supplier
      );
    },
    bills() {
      return (this.ad.orderLineItem.billLineItems || []).map(bli => ({
        ...bli.bill,
        lineItemAmount: bli.amount
      }));
    },
    billingPeriodDate() {
      return dateDb(this.billingPeriod);
    },
    periodBills() {
      return this.bills.filter(i => {
        return (
          dateDb(i.end_date) >= this.billingPeriodDate &&
          dateDb(i.start_date) <= this.billingPeriodDate
        );
      });
    },
    periodBilledAmount() {
      return this.periodBills.reduce((sum, i) => sum + i.lineItemAmount, 0);
    },
    isPeriodSynced() {
      return (
        this.periodBills.length > 0 &&
        this.periodBills.every(i => i.provider_id)
      );
    },
    isLifetimeSynced() {
      return this.bills.length > 0 && this.bills.every(i => i.provider_id);
    }
  }
};
</script>
