<template>
  <div class="send-invoices-button">
    <finance-tracker-mutation>
      <template v-slot="{ sendFinanceTrackerBuyerInvoices }">
        <confirm-dialog
          v-if="showConfirm"
          title="Are you sure?"
          width="40em"
          confirm-class="button-blue"
          confirm-text="Yes"
          :is-saving="isSending"
          @confirm="onSendInvoices(sendFinanceTrackerBuyerInvoices)"
          @close="showConfirm = false"
        >
          <div slot="title">
            <h2 class="text-2xl font-bold">
              Are you sure?
            </h2>
          </div>
          <p class="font-semibold text-md">
            This will send {{ records.length }}
            {{ 'Invoice' | pluralize(records.length) }}, do you want to proceed?
          </p>
        </confirm-dialog>

        <div class="flex space-x-4">
          <div class="flex items-center space-x-2">
            <h4 class="font-size-sm whitespace-no-wrap">Invoice Date</h4>
            <el-date-picker
              v-model="transactionDate"
              :clearable="false"
              type="date"
              placeholder="Select Month"
              format="MM/dd/yyyy"
            />
          </div>
          <el-button
            type="text"
            class="button button-green whitespace-no-wrap py-4 px-4"
            @click="showConfirm = true"
          >
            <div class="flex">
              Send {{ records.length }}
              {{ 'Invoice' | pluralize(records.length) }}
              <icon
                :icon="sendIcon"
                :class="{ 'animated loop tada': isSending }"
                :spin="isSending"
                class="ml-2"
              />
            </div>
          </el-button>
        </div>
      </template>
    </finance-tracker-mutation>
  </div>
</template>

<script>
import { paperPlane as sendIcon } from '@/vendor/icons';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import FinanceTrackerMutation from '@/components/Mutations/Accounting/FinanceTrackerMutation';
import moment from 'moment';
import { dateDb } from '@/utils/filters';

export default {
  components: {
    FinanceTrackerMutation,
    ConfirmDialog
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    records: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isSending: false,
      showConfirm: false,
      transactionDate: moment(this.filter.adDeliveryMonth).endOf('month'),
      sendIcon
    };
  },
  methods: {
    async onSendInvoices(sendFinanceTrackerBuyerInvoices) {
      this.isSending = true;

      let res = await sendFinanceTrackerBuyerInvoices(
        {
          ...this.filter,
          campaign_id: this.records.map(record => record.campaignId)
        },
        dateDb(this.transactionDate)
      );
      if (res.sendBuyerInvoices) {
        this.$message.success('Invoices have been sent!');
        this.$store.commit(
          'financeTrackerAds/setJobBatch',
          res.sendBuyerInvoices
        );
      } else {
        this.$message.error('Sending invoices failed, please try again.');
      }
      this.isSending = false;
      this.showConfirm = false;
    }
  }
};
</script>
