<template>
  <div
    class="finance-tracker-summary-item flex flex-col self-start"
    :class="{
      'border-right border-light-gray mr-4': bar,
      'pr-4': !loose,
      'pr-8': loose
    }"
  >
    <p class="font-size-default min-w-3.5" :class="{ 'font-bold': bold }">
      <slot />
    </p>
    <p class="text-md text-dark-silver whitespace-no-wrap">{{ label }}</p>
  </div>
</template>

<script>
export default {
  props: {
    bar: Boolean,
    bold: Boolean,
    loose: Boolean,
    label: {
      type: String,
      required: true
    }
  }
};
</script>
