<template>
  <div>
    <el-button
      class="button button-green whitespace-no-wrap"
      @click="showDialog = true"
    >
      Payments
      <icon :icon="previewIcon" class="ml-1" />
    </el-button>
    <finance-tracker-payable-suppliers-query
      v-if="showDialog"
      :filter="pager.filter"
      :loading.sync="isLoading"
      @data="onData($event)"
    />
    <finance-tracker-preview-dialog
      v-if="showDialog"
      :filter="pager.filter"
      category="Payments"
      :records="payableSuppliersSelection"
      :is-exporting="isExporting"
      @close="showDialog = false"
      @export="onExport"
    >
      <finance-tracker-payable-suppliers-table
        :records="payableSuppliers"
        :is-loading="isLoading"
        @selection="payableSuppliersSelection = $event"
      />
    </finance-tracker-preview-dialog>
  </div>
</template>

<script>
import FinanceTrackerPreviewButtonMixin from '@/components/Admin/Accounting/FinanceTracker/Buttons/FinanceTrackerPreviewButtonMixin';
import FinanceTrackerPreviewDialog from '@/components/Admin/Accounting/FinanceTracker/FinanceTrackerPreviewDialog';
import FinanceTrackerPayableSuppliersTable from '@/components/Admin/Accounting/FinanceTracker/Tables/FinanceTrackerPayableSuppliersTable';
import FinanceTrackerPayableSuppliersQuery from '@/components/Queries/Accounting/FinanceTrackerPayableSuppliersQuery';
import { exportCsv } from '@/utils/helpers';
import { eye as previewIcon } from '@/vendor/icons';

export default {
  components: {
    FinanceTrackerPayableSuppliersQuery,
    FinanceTrackerPayableSuppliersTable,
    FinanceTrackerPreviewDialog
  },
  mixins: [FinanceTrackerPreviewButtonMixin],
  data() {
    return {
      showDialog: false,
      isExporting: false,
      isLoading: false,
      payableSuppliers: null,
      payableSuppliersSelection: null,

      // Icons
      previewIcon
    };
  },
  computed: {
    pager() {
      return this.$store.getters['financeTrackerAds/pager'];
    },
    exportUrl() {
      return (
        window._env.API_URL +
        '/accounting/export/payableSuppliers?' +
        new URLSearchParams({
          filter: JSON.stringify(this.pager.filter)
        }).toString()
      );
    }
  },
  methods: {
    onData(ev) {
      this.payableSuppliers = this.prepareRecords(
        ev.nationalFinanceTracker.payableSuppliers
      );
    },
    async onExport() {
      this.isExporting = true;
      await exportCsv('PayableSuppliers.csv', this.exportUrl);
      this.isExporting = false;
    }
  }
};
</script>
