<template>
  <div>
    <el-tag v-if="numberOfDocumentsBeingDeleted > 0" type="danger">
      Deleting {{ numberOfDocumentsBeingDeleted }}
      {{ docType | pluralize(numberOfDocumentsBeingDeleted) }}
    </el-tag>
  </div>
</template>
<script>
export default {
  props: {
    docType: {
      type: String,
      default: 'document'
    },
    documents: {
      type: Array,
      required: true
    }
  },
  computed: {
    numberOfDocumentsBeingDeleted() {
      return this.documents.reduce(
        (d, count) => count + (d.delete_started_at ? 1 : 0),
        0
      );
    }
  }
};
</script>
