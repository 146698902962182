var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"finance-tracker-table"},[_c('el-table',{staticClass:"el-table-slim w-full no-header-ellipsis min-w-95",attrs:{"data":_vm.isLoading ? [] : _vm.ads,"border":""},on:{"sort-change":_vm.onSort},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.ads,"empty-message":"No Ads found","error-message":"There was a problem loading the ads. Please try again later."}})]},proxy:true}])},[_c('el-table-column',{attrs:{"label":"Ad Ref","align":"top","sortable":"custom","prop":"ref","fixed":"left","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_c('finance-tracker-ad-column',{attrs:{"ad":ad}})]}}])}),_c('el-table-column',{attrs:{"label":"Ad Date","align":"top","sortable":"custom","prop":"start_date","fixed":"left","min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_c('date-column',{attrs:{"start-date":ad.start_date,"end-date":ad.end_date}})]}}])}),_c('el-table-column',{attrs:{"label":"Status","align":"top","sortable":"custom","prop":"orderLineItem.status","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_c('div',{staticClass:"overflow-hidden ellipsis max-w-full"},[_c('h4',[_vm._v("Ad Status")]),_c('status-icon',{staticClass:"mt-1",attrs:{"icons":_vm.AdStatusInfo,"status":ad.status,"inline":""}})],1),_c('div',{staticClass:"mt-3"},[_c('h4',[_vm._v("Line Item Status")]),_c('status-icon',{staticClass:"mt-1",attrs:{"icons":_vm.OrderLineItemStatusIcons,"status":ad.orderLineItem.status,"inline":""}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"Booking Type","align":"top","sortable":"custom","prop":"orderLineItem.booking_type","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_vm._v(" "+_vm._s(ad.orderLineItem.booking_type)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Supplier","align":"top","sortable":"custom","prop":"supplier.display_name","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [(ad.supplier.is_test)?_c('icon',{staticClass:"mr-1",attrs:{"icon":_vm.testIcon}}):_vm._e(),_vm._v(" "+_vm._s(ad.supplier.display_name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Supplier Type","align":"top","sortable":"custom","prop":"supplier.organization_type","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_vm._v(" "+_vm._s(ad.supplier.organization_type)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Advertiser","align":"top","sortable":"custom","prop":"buyer.name","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_vm._v(" "+_vm._s(ad.buyer.display_name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Campaign","align":"top","sortable":"custom","prop":"campaign.name","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_c('a',{attrs:{"href":_vm.campaignNovaUrl(ad.campaign),"target":"_blank"}},[_vm._v(" "+_vm._s(ad.campaign.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Gross Revenue","align":"top","min-width":"250","sortable":"custom","prop":"grossRevenuePeriodDue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_c('finance-tracker-gross-revenue-column',{attrs:{"ad":ad,"billing-period":_vm.pager.filter.adDeliveryMonth},on:{"refresh":function($event){return _vm.$emit('refresh')}}})]}}])}),_c('el-table-column',{attrs:{"label":"Supplier Earnings","align":"top","sortable":"custom","min-width":"250","prop":"supplierEarningsPeriodDue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_c('finance-tracker-supplier-earnings-column',{attrs:{"ad":ad,"billing-period":_vm.pager.filter.adDeliveryMonth},on:{"refresh":function($event){return _vm.$emit('refresh')}}})]}}])}),_c('el-table-column',{attrs:{"label":"Net Revenue ($)","align":"top","min-width":"140","sortable":"custom","sort-by":"`netRevenueDollars`","prop":"netRevenueDollars"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(ad.netRevenueDollars))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Net Revenue (%)","align":"top","min-width":"140","sortable":"custom","prop":"netRevenuePercent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ad = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("percent")(ad.netRevenuePercent))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }