<template>
  <div>
    <el-popover
      v-if="documents.length > 0"
      placement="bottom"
      trigger="click"
      popper-class="overflow-hidden"
    >
      <accounting-document-table
        :documents="documents"
        show-line-item-amount
        allow-delete
        :is-syncing="isSyncing"
        @sync="onSync"
        @delete="documentToDelete = $event"
      />

      <a slot="reference">
        <div v-if="isSyncing">Syncing...</div>
        <div v-else-if="isDeleting" class="text-red">Deleting...</div>
        <div v-else>
          <slot>Documents</slot>
        </div>
      </a>
    </el-popover>
    <template v-else>
      <slot name="empty"><slot /></slot>
    </template>
    <confirm-dialog
      v-if="documentToDelete"
      confirm-class="button-red"
      :is-saving="isDeleting"
      width="25em"
      @confirm="onConfirmDelete"
      @close="documentToDelete = null"
    >
      Are you sure you want to delete this document?
    </confirm-dialog>
  </div>
</template>

<script>
import AccountingDocumentTable from '@/components/Admin/Accounting/AccountingDocumentTable';
import ConfirmDialog from '@/components/Core/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
    AccountingDocumentTable
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    fnDelete: {
      type: Function,
      required: true
    },
    fnSync: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isDeleting: false,
      isSyncing: false,
      documentToDelete: null
    };
  },
  methods: {
    async onSync(document) {
      this.isSyncing = true;
      await this.fnSync(document);
      this.isSyncing = false;
      this.$emit('change');
    },
    async onConfirmDelete() {
      this.isDeleting = true;
      await this.fnDelete(this.documentToDelete);
      this.documentToDelete = null;
      this.isDeleting = false;
      this.$emit('change');
    }
  }
};
</script>
