<script>
import {
  sendFinanceTrackerBuyerInvoices,
  sendFinanceTrackerSupplierPayments
} from '@/graphql/accounting/financeTrackerMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],
  mutation() {
    return {
      mutations: {
        sendFinanceTrackerBuyerInvoices(filter, transactionDate) {
          return this.post(sendFinanceTrackerBuyerInvoices, {
            filter,
            transactionDate
          });
        },
        sendFinanceTrackerSupplierPayments(filter, transactionDate) {
          return this.post(sendFinanceTrackerSupplierPayments, {
            filter,
            transactionDate
          });
        }
      }
    };
  }
};
</script>
