<script>
import { QueryMixin } from '@/components/Core/Helpers';
import { getFinanceTrackerPayableSuppliers } from '@/graphql/admin/accounting/financeTrackerQueries.graphql';

export default {
  mixins: [QueryMixin],
  props: {
    options: {
      type: Object,
      default: () => ({
        fetchPolicy: 'no-cache'
      })
    },
    filter: {
      type: Object,
      required: true
    }
  },
  query({ filter }) {
    return {
      query: getFinanceTrackerPayableSuppliers,
      variables: { filter }
    };
  }
};
</script>
