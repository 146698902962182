<template>
  <div class="finance-tracker-chargeable-buyers-table">
    <el-table
      ref="table"
      :data="isLoading ? [] : sortableRecords"
      border
      default-expand-all
      :max-height="tableMaxHeight"
      class="el-table-slim w-full el-table-nested"
      @selection-change="onSelect"
    >
      <template v-slot:empty>
        <table-empty-slot
          :is-loading="isLoading"
          :has-data="!!records"
          empty-message="No ads available to invoice. Try adjusting your filters."
          error-message="There was a problem loading the buyers. Please try again later."
        />
      </template>

      <el-table-column fixed="left" type="selection" width="40" />

      <el-table-column fixed="left" type="expand">
        <template v-slot="{ row }">
          <finance-tracker-nested-medium-type-table
            :records="row.mediumTypes"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="Buyer"
        align="top"
        sortable
        prop="buyerName"
        fixed="left"
      >
        <template v-slot="{ row }">
          {{ row.buyerName }}
        </template>
      </el-table-column>

      <el-table-column
        label="Campaign"
        align="top"
        sortable
        prop="campaignName"
      >
        <template v-slot="{ row }">
          {{ row.campaignName }}
        </template>
      </el-table-column>

      <el-table-column label="Medium Type" align="top" width="150" />

      <el-table-column
        label="Gross Revenue"
        align="right"
        sortable
        prop="grossRevenuePeriodDue"
        width="155"
      >
        <template v-slot="{ row }">
          {{ row.grossRevenuePeriodDue | currency }}
        </template>
      </el-table-column>

      <el-table-column
        label="Supplier Earnings"
        align="right"
        sortable
        prop="supplierEarningsPeriodDue"
        width="170"
      >
        <template v-slot="{ row }">
          {{ row.supplierEarningsPeriodDue | currency }}
        </template>
      </el-table-column>

      <el-table-column
        label="Net Revenue ($)"
        align="right"
        sortable
        prop="netRevenueDollars"
        width="170"
      >
        <template v-slot="{ row }">
          {{ row.netRevenueDollars | currency }}
        </template>
      </el-table-column>

      <el-table-column
        label="Net Revenue (%)"
        align="right"
        sortable
        prop="netRevenuePercent"
        width="170"
      >
        <template v-slot="{ row }">
          {{ row.netRevenuePercent | percent(1) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import FinanceTrackerNestedMediumTypeTable from '@/components/Admin/Accounting/FinanceTracker/Tables/FinanceTrackerNestedMediumTypeTable';
import FinanceTrackerTableMixin from '@/components/Admin/Accounting/FinanceTracker/Tables/FinanceTrackerTableMixin';

export default {
  components: {
    FinanceTrackerNestedMediumTypeTable,
    TableEmptySlot
  },
  mixins: [FinanceTrackerTableMixin]
};
</script>
