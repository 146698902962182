<template>
  <div>
    <el-table
      ref="table"
      border
      :data="isLoading ? [] : sortableRecords"
      :max-height="tableMaxHeight"
      default-expand-all
      class="el-table-slim w-full el-table-nested"
      @selection-change="onSelect"
    >
      <template #empty>
        <table-empty-slot
          :is-loading="isLoading"
          :has-data="!!records"
          empty-message="No Suppliers"
          error-message="There was a problem loading the suppliers. Please try again."
        />
      </template>

      <el-table-column fixed="left" type="selection" width="40" />

      <el-table-column v-slot="{ row: supplier }" type="expand">
        <finance-tracker-nested-medium-type-table
          :records="supplier.mediumTypes"
        />
      </el-table-column>
      <el-table-column
        v-slot="{ row: supplier }"
        sortable
        label="Supplier"
        prop="supplierName"
      >
        <icon v-if="supplier.isTest" :icon="testIcon" class="mr-1" />
        {{ supplier.supplierName }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: supplier }"
        label="Product Medium"
        width="150"
      />
      <el-table-column
        v-slot="{ row: supplier }"
        sortable
        label="Gross Revenue"
        prop="grossRevenuePeriodDue"
        align="right"
        width="155"
      >
        {{ supplier.grossRevenuePeriodDue | currency }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: supplier }"
        sortable
        label="Supplier Earnings"
        prop="supplierEarningsPeriodDue"
        align="right"
        width="170"
      >
        {{ supplier.supplierEarningsPeriodDue | currency }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: supplier }"
        sortable
        label="Net Revenue ($)"
        prop="netRevenueDollars"
        align="right"
        width="170"
      >
        {{ supplier.netRevenueDollars | currency }}
      </el-table-column>
      <el-table-column
        v-slot="{ row: supplier }"
        sortable
        label="Net Revenue %"
        prop="netRevenuePercent"
        align="right"
        width="170"
      >
        {{ supplier.netRevenuePercent | percent }}
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { lab as testIcon } from '@/vendor/icons';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import FinanceTrackerNestedMediumTypeTable from '@/components/Admin/Accounting/FinanceTracker/Tables/FinanceTrackerNestedMediumTypeTable';
import FinanceTrackerTableMixin from '@/components/Admin/Accounting/FinanceTracker/Tables/FinanceTrackerTableMixin';

export default {
  components: {
    FinanceTrackerNestedMediumTypeTable,
    TableEmptySlot
  },
  mixins: [FinanceTrackerTableMixin],
  data() {
    return { testIcon };
  }
};
</script>
