<template>
  <div class="accounting-document-table" @click.stop>
    <el-table
      :data="documents"
      border
      class="el-table-slim"
      :default-sort="{ prop: 'transaction_date', order: 'descending' }"
    >
      <template v-if="$can('manage_accounting_documents')">
        <el-table-column label="ID" sortable prop="provider_id" width="70">
          <template #default="{row}">
            <template v-if="row.is_tracked_by_source">
              {{ row.provider_id || 'Not Synced' }}
            </template>
            <template v-else>N/A</template>
          </template>
        </el-table-column>

        <el-table-column label="Ref" sortable prop="ref" width="80" />

        <el-table-column label="User" sortable prop="user.email" width="160" />
      </template>

      <el-table-column
        label="Doc Number"
        sortable
        prop="doc_number"
        width="170"
      />

      <el-table-column
        label="Date"
        sortable
        prop="transaction_date"
        width="110"
      >
        <template v-slot="{ row }">{{ row.transaction_date | date }}</template>
      </el-table-column>

      <el-table-column label="Period" sortable prop="start_date" width="110">
        <template v-slot="{ row }"
          >{{ row.start_date | date }} to {{ row.end_date | date }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="showLineItemAmount"
        label="Amount"
        sortable
        prop="lineItemAmount"
        width="120"
      >
        <template v-slot="{ row }">
          {{ row.lineItemAmount | currency }}
        </template>
      </el-table-column>

      <el-table-column label="Bill Total" sortable prop="amount" width="120">
        <template v-slot="{ row }">{{ row.amount | currency }}</template>
      </el-table-column>

      <el-table-column label="Status" sortable prop="status" width="100">
        <template v-slot="{ row }">
          <el-tag :type="getStatusTagColor(row)">
            {{ getStatus(row) }}
          </el-tag>
        </template>
      </el-table-column>

      <template v-if="$can('manage_accounting_documents')">
        <el-table-column label="Url" sortable prop="provider_url" width="350">
          <template v-slot="{ row }">
            <template v-if="!row.delete_started_at">
              <template v-if="row.is_tracked_by_source">
                <a
                  v-if="row.provider_id"
                  :href="row.provider_url"
                  target="_blank"
                >
                  {{ row.provider_url }}
                </a>
                <a v-else @click="$emit('sync', row.id)">
                  <template v-if="isSyncing">Syncing...</template>
                  <template v-else>Sync To Provider</template>
                </a>
              </template>
              <template v-else>
                (Not tracked in QuickBooks)
              </template>
            </template>
          </template>
        </el-table-column>

        <el-table-column v-if="allowDelete" width="60" align="center">
          <template v-slot="{ row }">
            <a
              v-if="!isDeleting(row)"
              class="text-lg"
              @click.stop="$emit('delete', row.id)"
            >
              <icon :icon="deleteIcon" />
            </a>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import { AccountingDocumentStatus, InvoiceStatus } from '@/constants';
import { trashEmpty as deleteIcon } from '@/vendor/icons';

export default {
  props: {
    isSyncing: Boolean,
    documents: {
      type: Array,
      required: true
    },
    showLineItemAmount: Boolean,
    allowDelete: Boolean
  },
  data() {
    return {
      AccountingDocumentStatus,
      deleteIcon
    };
  },
  methods: {
    isDeleting(row) {
      return !!row.delete_started_at;
    },
    getStatus(row) {
      let status = AccountingDocumentStatus[row.status];
      if (this.isDeleting(row)) {
        status = InvoiceStatus.DELETING.label;
      }
      return status;
    },
    getStatusTagColor(row) {
      if (this.getStatus(row) === InvoiceStatus.DELETING.label) {
        return 'danger';
      }
      return 'info';
    }
  }
};
</script>
