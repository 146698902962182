<script>
import { getFinanceTrackerAdsSummary } from '@/graphql/admin/accounting/financeTrackerQueries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    pager: {
      type: Object,
      required: true
    }
  },

  query(props) {
    let pager = props.pager || this.$store.getters['financeTrackerAds/pager'];

    return {
      query: getFinanceTrackerAdsSummary,
      variables: {
        filter: pager.filter
      }
    };
  }
};
</script>
