<script>
export default {
  methods: {
    prepareRecords(records) {
      records = records.map(record => {
        record.supplierEarningsPeriodDue = +record.supplierEarningsPeriodDue;
        record.grossRevenuePeriodDue = +record.grossRevenuePeriodDue;
        return record;
      });
      return records;
    }
  }
};
</script>
