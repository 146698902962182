<template>
  <accounting-mutation>
    <template v-slot="{ isSaving, syncBillToProvider, deleteBill }">
      <finance-tracker-document-popover
        :documents="bills"
        :fn-delete="deleteBill"
        :fn-sync="syncBillToProvider"
        @change="$emit('change')"
      >
        <slot />
      </finance-tracker-document-popover>
    </template>
  </accounting-mutation>
</template>
<script>
import AccountingMutation from '@/components/Mutations/Admin/AccountingMutation';
import FinanceTrackerDocumentPopover from '@/components/Admin/Accounting/FinanceTracker/FinanceTrackerDocumentPopover';

export default {
  components: { AccountingMutation, FinanceTrackerDocumentPopover },
  props: {
    bills: {
      type: Array,
      required: true
    }
  }
};
</script>
