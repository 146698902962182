<script>
import { getFinanceTrackerAds } from '@/graphql/admin/accounting/financeTrackerQueries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    pager: {
      type: Object,
      required: true
    }
  },

  query(props) {
    return {
      query: getFinanceTrackerAds,
      variables: {
        ...(props.pager || this.$store.getters['financeTrackerAds/pager'])
      }
    };
  }
};
</script>
