<template>
  <a @click="onClick">
    <icon :icon="refreshIcon" :spin="isRefreshing" />
  </a>
</template>
<script>
import { arrowsCw as refreshIcon } from '@/vendor/icons';

export default {
  props: {
    isRefreshing: Boolean,
    pager: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      refreshIcon
    };
  },
  methods: {
    onClick() {
      this.$emit('click');
      this.$emit('update:pager', { ...this.pager });
    }
  }
};
</script>
